import React from 'react';
import logo from '../logo.png'; // Ensure the path to logo.png is correct based on your directory structure

function Welcome() {
    return (
        <div className="welcome-border">
            <img src={logo} alt="Easy Alien Logo" className="centered-image" />
            <div className="welcome-message">
                <p>Easy Alien</p>
                <p>Fan Club</p>
            </div>
        </div>
    );
}

export default Welcome;
