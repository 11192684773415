import React, { useEffect, useRef } from 'react';

function StarField() {
    const spaceRef = useRef(null);

    useEffect(() => {
        const space = spaceRef.current;
        const numStars = 1000; // You can adjust the number of stars here

        for (let i = 0; i < numStars; i++) {
            let star = document.createElement('div');
            star.className = 'star';
            setStarStyle(star);
            space.appendChild(star);
        }

        // Cleanup function to remove stars when the component unmounts
        return () => {
            while (space.firstChild) {
                space.removeChild(space.firstChild);
            }
        };
    }, []);

    function setStarStyle(star) {
        star.style.width = `${Math.random() * 3 + 1}px`;
        star.style.height = star.style.width;
        star.style.left = `${Math.random() * window.innerWidth}px`;
        star.style.top = `${Math.random() * window.innerHeight}px`;

        const hue = Math.random() * 360;
        const brightness = 70 + Math.random() * 30;
        const blurAmount = Math.random() * 2;

        star.style.backgroundImage = `radial-gradient(circle, hsla(${hue}, 100%, ${brightness}%, 0.8), transparent 70%)`;
        star.style.boxShadow = `0 0 12px hsla(${hue}, 100%, ${brightness}%, 0.8)`;
        star.style.filter = `blur(${blurAmount}px) brightness(${brightness}%)`;
    }

    return <div id="space" ref={spaceRef} />;
}

export default StarField;
