import React from 'react';
import './App.css'; // Importing the main stylesheet
import Welcome from './components/Welcome'; // Importing the Welcome component
import StarField from './components/Starfield'; // Importing the StarField component

function App() {
    return (
        <div id="fixed-space">
            <Welcome />
            <StarField />
        </div>
    );
}

export default App;
